<script setup lang="ts">
import { useEditablePage } from '/~/composables/cms/use-editable-page'

const { page, haveRightWidget } = useEditablePage()
</script>

<template>
  <div
    class="mx-auto h-full w-full px-5 pt-5 md:max-w-7xl"
    data-cy="ewallet-home"
  >
    <portal to="right-side">
      <widgets-builder
        v-if="haveRightWidget"
        :widgets="page.widgets"
        side="right"
        class="w-full min-w-min md:w-[376px] md:shrink-0"
      />
    </portal>
    <widgets-builder
      v-if="page && page.widgets"
      :widgets="page.widgets"
      side="center"
      class="pb-10"
    />
  </div>
</template>
